<template>
  <div class="brandUpdateRescords">
    <div
      class="simpleInfoPanel"
      v-if="hasPermission"
    >
      <el-table
        :data="tableData"
        class="tbsWrap"
        highlight-current-row
      >
        <el-table-column
          prop="name"
          label="品牌名称"
        >
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="`brandDetail?id=${scope.row.id}&source=1&questionState=0&gbid=${scope.row.gbid}`"
            >
              {{scope.row.name}}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="formatType"
          label="业态类别"
        >
        </el-table-column>
        <el-table-column
          prop="update"
          label="同步更新"
        >
        </el-table-column>
        <el-table-column
          prop="updateDate"
          label="最近同步时间"
        >
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          layout="total, prev, pager, next"
          v-if="params.total>10"
          :total="params.total"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @current-change="handlePages"
        >
        </el-pagination>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'brandUpdateRescords',
  components: {},
  data () {
    return {
      tableData: [],
      hasPermission: true,
      userAuthorities: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        pages: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    user (newVal, oldVal) {
      console.log('newValoldVal', newVal, oldVal)
    }
  },
  mounted () {
    console.log('user', this.user)
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 80
    })
    if (this.userAuthorities.length) {
      this.hasPermission = true
      this.getDataRecordsList()
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    getDataRecordsList () {
      this.axios.post(api.getSyncBrandList, this.params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          const updateTitle = {
            update: '更新',
            add: '新增',
            delete: '删除',
            modified: '更新',
            insert: '新增'
          }
          this.tableData = resData.dataList.map(item => {
            const tempObj = {
              name: item.brandName,
              formatType: item.operationType,
              updateDate: item.syncTimeString,
              update: updateTitle[item.synType],
              id: item.brandId,
              gbid: item.gbid
            }
            return tempObj
          })
          this.params.total = resData.total
          this.params.pageNum = resData.pageNum
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handlePages (val) {
      this.params.pageNum = val
      this.getDataRecordsList()
    }
  }
}
</script>

<style scoped lang="stylus"></style>
